import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/h2r_vehicule.jpg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionOurProductsProps {
  className?: string;
}

const SectionOurProducts: FC<SectionOurProductsProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Découvrez nos produits!</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Notre societé vous propose un large choix de produits pour répondre au mieux à votre besoin. Nous nous occupons de la livraison et du montage, une offre clé en main !
        </span>
        <ButtonPrimary href={"/materials-list"} className="sm:mt-6" >Voir les produits</ButtonPrimary>
      </div>
      <div className="flex-grow ">
        <NcImage className="rounded-2xl" src={rightImg} />
      </div>
    </div>
  );
};

export default SectionOurProducts;
