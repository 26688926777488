import { Address } from "data/types";
import { FC } from "react";
import FormItem from "shared/FormItem/FormItem";
import Input from "shared/Input/Input";

export interface AddressDisplayProps {
  Address?: Address | null
  className?: string
}

const AddressDisplay : FC<AddressDisplayProps> = ({
  Address,
  className,
  }) => {

  if(Address) {
  const displayStreet = (pAddress:Address) => {

    const typeDisplay: string[] = ["housenumber", "street"];

    if (typeDisplay.indexOf(pAddress.type) > -1){
      return (pAddress.street ? pAddress.street : pAddress.name);
    } else {
      return ("");
    }
  }

    const displayNumber = (pAddress:Address) => {
        return (pAddress.housenumber ? pAddress.housenumber : "");
    }

    const displayCity = (pAddress:Address) => {
        return (pAddress.oldcity ? pAddress.city +" ("+pAddress.oldcity+")" : pAddress.city);
    }

    return (
      <div className={className}>
        <div className="grid grid-cols-6 gap-6">
          <FormItem
            label="Numéro"
            className="col-span-1"
          >
            <Input
              value={displayNumber(Address)}
              defaultValue=""
              className={"bg-slate-200 cursor-not-allowed"}
              type="text"
              disabled
              readOnly
            />
          </FormItem>

          <FormItem
            label="Rue"
            className="col-span-5"
          >
            <Input
              value={displayStreet(Address)}
              defaultValue=""
              className={"bg-slate-200 cursor-not-allowed"}
              disabled
              readOnly
            />
          </FormItem>
        </div>
        <div className="grid grid-cols-6 gap-6">
          <FormItem
            label="Code postal"
            className="col-span-1"
          >
            <Input
              value={Address.postcode }
              className={"bg-slate-200 cursor-not-allowed"}
              defaultValue=""
              disabled
              readOnly
            />
          </FormItem>

          <FormItem
            label="Ville"
            className="col-span-5"
          >
            <Input
              value={displayCity(Address)}
              defaultValue=""
              className={"bg-slate-200 cursor-not-allowed"}
              disabled
              readOnly
            />
          </FormItem>
        </div>
        <FormItem label="Département/Région">
          <Input
            value={Address.context }
            defaultValue=""
            className={"bg-slate-200 cursor-not-allowed"}
            disabled
            readOnly
          />

        </FormItem>
      </div>
    );
  }
  
  return(
    <></>
  ); 
}

export default AddressDisplay;