import { FieldInputProps, FieldMetaProps, FormikProps, getIn } from "formik";
import { FC } from "react";
import FormItem, { FormItemProps } from "shared/FormItem/FormItem";
import Textarea, { TextareaProps } from "shared/Textarea/Textarea";

export interface FormikTailWildTextareaProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  meta: FieldMetaProps<any>;
  formItemProps: FormItemProps | null,
  textareaProps: TextareaProps | null
  className?: string,
  customOnChange?: (value: any) => void
}

const FormikTailWildTextarea : FC<FormikTailWildTextareaProps> = ({
  field,
  form,
  formItemProps,
  textareaProps,
  className,
  customOnChange = (value:any) => {}
  // form: {touched: boolean, errors: string},
}) => {
  return (
    <>
      <FormItem
      {...formItemProps}
      desc={getIn(form.touched, field.name) && getIn(form.errors, field.name) ? getIn(form.errors, field.name) : ""}
      error={getIn(form.touched, field.name) && getIn(form.errors, field.name)}
      className={className}
      >
        <Textarea {...field} {...textareaProps} error={getIn(form.touched, field.name) && getIn(form.errors, field.name)} onChange={(e) => {field.onChange(e); customOnChange(e) }}   />
          {/* {getIn(form.touched, field.name) && getIn(form.errors, field.name) ? <p>{getIn(form.errors, field.name)}</p> : ""} */}
      </FormItem>

    </>

  )

}

export default FormikTailWildTextarea;