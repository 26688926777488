import AddressDisplay from "components/AddressSearch/AddressDisplay";
import AddressSearch from "components/AddressSearch/AddressSearch";
import FormikTailWildInput from "components/FormikTailwild/FormikTailWildInput";
import { Address, Person } from "data/types";
import { FormContextRental } from "FormContextRental";
import { Field, Formik } from "formik";
import React, { FC, useContext, useState } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import FormItem from "shared/FormItem/FormItem";
import * as Yup from "yup"

export interface PersonInformationsProps {
  className?: string;
  handlePerson?: (person: Person) => void
  defaultValues? : Person | null
}

const PersonInformations: FC<PersonInformationsProps> = ({ 
  className = "", 
  handlePerson = (person: Person) => {},
  defaultValues
  }) => {

  const [selectedAddress, setSelectedAddress] = useState<Address| null>();
  const formContextRental = useContext(FormContextRental);

  const PersonnValidation = Yup.object().shape({
    name: Yup.string().required("Un nom est requis"),
    firstname: Yup.string().required("Un prénom est requis"),
    phoneNumber: Yup.string().required("Un numéro de téléphone est requis"),
    email: Yup.string().email("Email valide requis").required("Un email est requis"),
  });

  
  const setAddress = (addressParam: Address | null) => {
    setSelectedAddress(addressParam);
    personState.address = addressParam;
    setPersonState(personState);
  }

  const person : Person = {
    id: null,
    name: "",
    firstName: "",
    phoneNumber: "",
    email: "",
    address: null
  }

  const [personState, setPersonState] = useState<Person>(defaultValues ? defaultValues : person);
  const [checkedState, setCheckedState] = useState<boolean>(true);

  const handleOnChangePerson = (person: Person) => {
    setPersonState(person);
    handlePerson(person);
  }

  const checkAddress = (checked:boolean) =>{
    if(checked) {
      setAddress(formContextRental.addressSelected!);
    } else {
      setAddress(null);
      setCheckedState(false);
    }

  }

  if(personState.address === null && checkedState){
    checkAddress(true);
  }


  return (
    <>
      <Formik
        initialValues={defaultValues ? defaultValues : person}
        validationSchema={PersonnValidation}
        onSubmit={(values) => {
        }}
      >

        <div>
          <h3>Informations personnelles de facturation</h3>
          <div className="flex flex-col grid grid-cols-2 space-y-4 space-x-4">
            <Field 
              component={FormikTailWildInput} 
              name="name" 
              formItemProps={{label: "Votre nom"}} 
              className={"mt-4 ml-4"}
              customOnChange={(value: any) => {
                personState.name = value.currentTarget.value;
                handleOnChangePerson(personState);
              }}
            />
            <Field 
              component={FormikTailWildInput} 
              name="firstName" 
              formItemProps={{label: "Votre prénom"}} 
              customOnChange={(value: any) => {
                personState.firstName = value.currentTarget.value;
                handleOnChangePerson(personState);
              }}
            />
            <Field 
              component={FormikTailWildInput} 
              name="phoneNumber" 
              formItemProps={{label: "Votre numéro de téléphone"}} 
              customOnChange={(value: any) => {
                personState.phoneNumber = value.currentTarget.value;
                handleOnChangePerson(personState);
              }}
            />
            <Field 
              component={FormikTailWildInput} 
              name="email" 
              formItemProps={{label: "Votre email"}}
              customOnChange={(value: any) => {
                personState.email = value.currentTarget.value;
                handleOnChangePerson(personState);
              }}
            />
          </div>
          <FormItem
            label="Adresse de facturation"
            className="mt-5"
          >
            <AddressSearch handleAddress={setAddress} rawAddress="" desc="Commencez à taper pour trouver votre adresse" />
          </FormItem>
          <Checkbox
            label="Utiliser l'adresse de livraison comme adresse de facturation"
            name="DEFAULTADDRESS"
            className="mb-5"
            onChange={checkAddress}
            defaultChecked={checkedState}
          />
          {(selectedAddress || defaultValues?.address) && 
              <p className="mb-2 mt-3">Adresse prise en compte</p>
          }
          <AddressDisplay Address={defaultValues?.address ? defaultValues.address : selectedAddress} className="space-y-4" />
        </div>

      </Formik>

      
    </>
  );
};

export default PersonInformations;
