const LegalNotices = () => {
  return (
    <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">
      <div className="space-y-6 sm:space-y-8">
        {/* HEADING */}
        <h2 className="text-3xl font-semibold">Mentions légales</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="max-w-4xl">
          <span className="text-xl font-semibold block">Identification de l'entreprise</span>
          <br />
          <span className="text-justify text-neutral-700 dark:text-neutral-300 block">
            <u><b>H2R Event (EI)</b></u><br/>
            Siège social : 3024 ROUTE DE LA BOISSIÈRE,
            14100 Saint-Pierre-des-Ifs, France <br /> 
            hr2.event@gmail.com<br />
            <br/>
            SIREN: 902 134 147<br/>
            SIRET: 902 134 147 00013<br/>
            APE: 902134147
          </span>
          <br/>
          <span className="text-xl font-semibold block">Conditions d'utilisation du site Internet h2revent-lisieux.fr</span>
          <br />
          <span className="text-justify text-neutral-700 dark:text-neutral-300 block">
          Le site et chacun des éléments, y compris mais sans limitation les marques, les logos, icônes, infographies, photographies, qui le composent sont protégés au titre de la législation internationale de la propriété intellectuelle. Les contenus figurant sur le site sont la propriété d'H2REvent ou d’autres entreprises.
          Toute utilisation, reproduction ou représentation, par quelque procédé que ce soit, et sur quelque support que ce soit, de tout ou partie du site et/ou des éléments qui le composent n'est pas autorisée sans le consentement expresse d'H2REvent.<br/>
          </span>
          <br/>
          <span className="text-xl font-semibold block">Données personnelles</span>
          <br />
          <span className="text-justify text-neutral-700 dark:text-neutral-300 block">
            D'une façon générale, vous pouvez visiter notre site sur Internet sans avoir à décliner votre identité et à fournir des informations personnelles vous concernant. 
            Cependant, nous pouvons parfois vous demander des informations. 
            Par exemple, pour traiter une commande, établir une correspondance ou fournir un abonnement. Nous pouvons compléter ces informations pour conclure une transaction ou offrir un meilleur service.
          </span>
          <br/>
          <span className="text-xl font-semibold block">Conditions d'utilisation du site Internet h2revent-lisieux.fr</span>
          <br />
          <span className="text-justify text-neutral-700 dark:text-neutral-300 block">
          Le site et chacun des éléments, y compris mais sans limitation les marques, les logos, icônes, infographies, photographies, qui le composent sont protégés au titre de la législation internationale de la propriété intellectuelle. Les contenus figurant sur le site sont la propriété d'H2REvent ou d’autres entreprises.
          Toute utilisation, reproduction ou représentation, par quelque procédé que ce soit, et sur quelque support que ce soit, de tout ou partie du site et/ou des éléments qui le composent n'est pas autorisée sans le consentement expresse d'H2REvent.<br/>
          </span>
        </div>
      </div>
  </div>
  )
}

export default LegalNotices;