import { FormContextRental } from "FormContextRental";
import React, { FC, useContext, useState } from "react";
import FormItem from "shared/FormItem/FormItem";
import CompanyInformations from "./CompanyInformations";
import PersonInformations from "./PersonInformations";

export interface ContactInformationsProps {
  className?: string;
}

const ContactInformations: FC<ContactInformationsProps> = ({ className = "", }) => {

  const [typeContact, setTypeContact] = useState<string>("individual");

  const context = useContext(FormContextRental);

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    defaultChecked?:boolean
  ) => {
    return (
      <div className="flex items-center">
        <input
          defaultChecked={defaultChecked}
          id={id + name}
          name={name}
          value={id}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
          onChange={(e) => {
            setTypeContact(e.currentTarget.value)
            if(e.currentTarget.value === "individual") {context.setContactCompany(null)}
          }}
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  }

  return (
    <FormContextRental.Consumer>
      {formValues => 
      <>
        {/* {setTypeContact(formValues.contactCompany ? "company" : "individual")} */}
        <FormItem
          label="Quel est votre statut ?"
        >
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {renderRadio("TypeContact", "individual", "Particulier", formValues.contactCompany ? false : true)}
            {renderRadio("TypeContact", "company", "Entreprise", formValues.contactCompany ? true : false)}
          </div>
        </FormItem>
        {(typeContact === "company" || formValues.contactCompany) &&
          <CompanyInformations defaultValues={formValues.contactCompany} handleCompany={formValues.setContactCompany} />
        }
        <PersonInformations defaultValues={formValues.contactPerson} handlePerson={formValues.setContactPerson}/>
      </>
      }


    </FormContextRental.Consumer>
  );
};

export default ContactInformations;
