import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Category, Material } from "data/types";
import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import SectionGridFilterCard from "containers/EnquipementRental/SectionGridFilterCard";
import Heading2 from "components/Heading/Heading2";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface ListingProductsPageProps {
  className?: string;
}

const ListingProductsPage: FC<ListingProductsPageProps> = ({ className = "" }) => {

  const [materialsList, setMaterialsList] = useState<Material[]>();
  const [categoriesList, setCategoriesList] = useState<Category[]>();

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const materialsRequest = axios.get("Materials");
    const categoriesRequest = axios.get("Categories/WithoutUnavailable");
    
    axios.all([materialsRequest, categoriesRequest]).then(r => {
      if (r[0].status === 200){
        setMaterialsList(r[0].data.result);
      }

      if (r[1].status === 200){
        setCategoriesList(r[1].data.result);
      }

      setLoaded(true);
    });
  }, [loaded])

  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <BgGlassmorphism />

      <div className="container relative">
      <Heading2
        className="sm:mt-6"
        heading={"Nos produits"}
        subHeading={
          <>
                    <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            Voici l'ensemble des produits que nous pouvons vous mettre à disposition!
          </span>

          </>

        }
      />
      
        {/* SECTION */}
        { loaded && (materialsList && categoriesList)   && 
          <SectionGridFilterCard 
            data={materialsList} 
            categories={categoriesList} 
            cardDisplayMoreButton={true} 
            cardDisplayQuantity={false}
            cardDisplaySelectedQuantity={false}
          />
        }
        { !loaded && 
          <div className="flex justify-center items-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>Chargements ...</span>
          </div>
        }

        <div className="flex justify-center items-center sm:mt-6 mb-6">
          <ButtonPrimary  href="/materials-rental-step1" className="">Commencez votre réservation dès maintenant</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default ListingProductsPage;
