import React, { FC } from "react";
import Button from "shared/Button/Button";
import twFocusClass from "utils/twFocusClass";

export interface PaginationGridCardProps {
  className?: string;
  currentPage: number;
  lastPage: number;
  onChange: (n: number) => void;
}

const PaginationGridCard: FC<PaginationGridCardProps> = ({ currentPage, lastPage, onChange, className = "" }) => {
  const renderItem = () => {

    let result:any = [];

    for (let i = 1; i <= lastPage; i++) {
      if (i === currentPage) {
        // RETURN ACTIVE PAGINATION
        result.push(
          <span
            key={i}
            className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white my-5 ${twFocusClass()}`}
          >
            {i}
          </span>
        );
      } else {
        // RETURN UNACTIVE PAGINATION
        result.push(
          <Button
            key={i}
            className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 my-5 ${twFocusClass()}`}
            onClick={()=> onChange(i)}
            type="button"
          >
            {i}
          </Button>
        );
      }
    }

    return result;
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {renderItem()}
    </nav>
  );
};

export default PaginationGridCard;
