import SectionHero from "components/SectionHero/SectionHero";
import React from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import SectionOurProducts from "components/SectionOurProducts/SectionOurProducts";

function PageHomeH2R() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>H2R Event Lisieux || Location de matériels de récéption</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-20 pb-16" />

        {/* SECTION 1 */}
        <SectionOurProducts />
       
      </div>
    </div>
  );
}

export default PageHomeH2R;
