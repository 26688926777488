import React, { FC, useContext, useEffect, useState } from "react";
import { Category, Material } from "data/types";
import TabFilters from "./TabFilters";
import MaterialCard from "components/MaterialCard/MaterialCard";
import PaginationGridCard from "./PaginationGridCard";
import { FormContextRental } from "FormContextRental";
export interface SectionGridFilterCardProps {
  className?: string;
  data: Material[];
  categories: Category[],
  cardDisplaySelectedQuantity?: boolean,
  cardDisplayQuantity?: boolean,
  cardDisplayMoreButton?: boolean,
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
  categories,
  cardDisplaySelectedQuantity = true,
  cardDisplayQuantity = true,
  cardDisplayMoreButton =  false,
}) => {

  const [totalItem, setTotalItem] = useState<number>(data.length);
  const itemsPerPage: number = 8;
  const [last_page, setLastPage] = useState<number>(Math.ceil(totalItem / itemsPerPage));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentCategoriesUsed, setCurrentCategoriesUsed] = useState<number[]>([])
  const [currentSubCategoriesUsed, setCurrentSubCategoriesUsed] = useState<number[]>([]);


  const sortResult = (toSort :Material[], page = currentPage, itemsPage = itemsPerPage) => {

    // sort alphabetic
    let sort =  toSort
    .sort((a,b) =>  a.category_id > b.category_id ? 1 : 0)
    .sort((a) => a.available_quantity === 0 ? 1:0);

      const filtered = sort
                          .filter(mat => currentCategoriesUsed.findIndex(categoryId =>  categoryId === mat.category_id) > -1)
                          .filter(mat => (currentSubCategoriesUsed.findIndex(subCategoryId =>  subCategoryId === mat.sub_category_id) > -1) || mat.sub_category_id == null);

                          console.log("filtered", filtered, filtered.length);
      setTotalItem(filtered.length);

      return filtered
                  .slice((page - 1) * itemsPage, page * itemsPage);
  }

  const[displayItems, setDisplayItems] = useState<Material[]>(data);

  useEffect(() => {
    const allCategories: number[] = [];
    const subCategories: number[] = [];
    categories.forEach((cat,i) => {
      allCategories.push(cat.id)
      if (cat.sub_categories) {
          cat.sub_categories.forEach(subcat => {
          subCategories.push(subcat.id);
        });
      }
    });
    setCurrentCategoriesUsed(allCategories);
    setCurrentSubCategoriesUsed(subCategories);
    setDisplayItems(sortResult(data, 1));
  }, [categories])

  const onCurrentPageChange = (n: number) => {
    window.scrollTo(0, 0)
    if(n>last_page){
      setCurrentPage(1);
    } else {
      setCurrentPage(n);
    }

    setDisplayItems(sortResult(data, n));
  };

  useEffect(() => {
    setLastPage(Math.ceil(totalItem/itemsPerPage));
  }, [totalItem]);

  const updateCategoriesUsed = (categoryId: number) => {
    const i = currentCategoriesUsed.findIndex((id) => categoryId === id);

    const realIndex = categories.findIndex((cat) => cat.id === categoryId);
    if (i > -1) {
       // remove category
      currentCategoriesUsed.splice(i, 1);
      if (realIndex > -1 ) {
        categories[realIndex].sub_categories?.forEach(subcat => {
          const i = currentSubCategoriesUsed.findIndex((id) => subcat.id === id);
          if (i > -1) {
            // remove subcategory
            currentSubCategoriesUsed.splice(i, 1);
            }
        })
      }
    } else {
      // add
      currentCategoriesUsed.push(categoryId);
      if (realIndex > -1 ) {
        categories[realIndex].sub_categories?.forEach(subcat => {
          const i = currentSubCategoriesUsed.findIndex((id) => subcat.id === id);
          if (i === -1) {
            // remove subcategory
            currentSubCategoriesUsed.push(subcat.id);
            }
        });
      }
      // remove subcategories to notused

    }

    setCurrentCategoriesUsed(currentCategoriesUsed);
    setDisplayItems(sortResult(data, 1));
    setCurrentPage(1);
  }

  const updateSubCategoryUsed = (subCategoryId: number) => {
    const i = currentSubCategoriesUsed.findIndex((id) => subCategoryId === id);

    if (i > -1) {
      // remove subcategory
      currentSubCategoriesUsed.splice(i, 1);

   } else {
     // add
     currentSubCategoriesUsed.push(subCategoryId);
   }
   setCurrentSubCategoriesUsed(currentSubCategoriesUsed);
   setDisplayItems(sortResult(data, 1));
   setCurrentPage(1);
  }

  const formContextRental = useContext(FormContextRental);

  const updateDesiredItem = (quantity: number, pmaterial: Material) => {
    const indexMaterialDesired = formContextRental.desiredMaterials.findIndex(m => m.material.id === pmaterial.id);
    if (indexMaterialDesired > -1) {
      if(quantity === 0) {
        formContextRental.desiredMaterials.splice(indexMaterialDesired,1);
        formContextRental.setDesiredMaterials(formContextRental.desiredMaterials)
      } else {
        formContextRental.desiredMaterials[indexMaterialDesired].quantity = quantity;
        formContextRental.setDesiredMaterials(formContextRental.desiredMaterials);
      }
    } else if (quantity !== 0) {
      formContextRental.desiredMaterials.push({material: pmaterial, quantity: quantity});
    }
  }
  
  const currentMaterialQuantity = (pmaterial: Material) => {
    const indexMaterialDesired = formContextRental.desiredMaterials.findIndex(m => m.material.id === pmaterial.id);
    if (indexMaterialDesired > -1) {
      return formContextRental.desiredMaterials[indexMaterialDesired].quantity;
    }
    return 0;
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="mb-8 lg:mb-11">
        <TabFilters 
          categories={categories} 
          categoriesUsed={currentCategoriesUsed} 
          updateCategoryUsed={updateCategoriesUsed}
          subCategoriesUsed={currentSubCategoriesUsed}
          updateSubCategory={updateSubCategoryUsed}
        />
      </div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {displayItems.map((material) => (
          <MaterialCard 
            key={material.id} 
            data={material} 
            updateDesiredMaterial={updateDesiredItem} 
            defaultQuantity={currentMaterialQuantity(material)}
            displayMoreButton={cardDisplayMoreButton}
            displayQuantity={cardDisplayQuantity}
            displaySelectedQuantity={cardDisplaySelectedQuantity}
            />
        ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <PaginationGridCard currentPage={currentPage} lastPage={last_page} onChange={onCurrentPageChange} />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
