import React, { TextareaHTMLAttributes } from "react";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    sizeClass?: string;
    fontClass?: string;
    rounded?: string;
    error?:boolean;
  }

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ 
    className = "", 
    sizeClass = "",
    fontClass = "text-sm font-normal",
    rounded = "rounded-2xl",
    children, 
    error=false,
    ...args 
  }, ref) => {
    const borderClass = error ? "!border-red-500" : "border-neutral-200";
    return (
      <textarea
        ref={ref}
        className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${borderClass} ${rounded} ${fontClass} ${sizeClass} ${className}`}
        rows={4}
        {...args}
      >
        {children}
      </textarea>
    );
  }
);

export default Textarea;
