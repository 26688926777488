
import AddressDisplay from "components/AddressSearch/AddressDisplay";
import { Person } from "data/types"
import { FC } from "react"
import FormItem from "shared/FormItem/FormItem";
import Input from "shared/Input/Input";

export interface DisplayPersonInformationsProps {
  person: Person | null | undefined
}

const DisplayPersonInformations: FC<DisplayPersonInformationsProps> = ({
  person,
  }) => {

    if(!person) {
      return (
        <></>
      );
    }
    const isOk = (value: string | null | undefined) => {
      if(!value) {
        return false;
      } else if (value.replace(" ", "") === "") {
        return false;
      }
      return true;
    }

    return (
      <div>
        <div className="flex flex-col grid grid-cols-2 space-y-4 space-x-4">
          <FormItem 
            label="Votre nom"
            desc={isOk(person.name) ? "" : "Un nom est obligatoire" }
            error={!isOk(person.name)}
            className={"mt-4 ml-4"}
          >
            <Input 
              name="namePerson" 
              disabled={true} 
              value={person.name}  
              error={!isOk(person.name)} 
              className={"bg-slate-200 cursor-not-allowed"}
            />
          </FormItem>
          
          <FormItem 
            label="Votre prénom"
            desc={isOk(person.firstName) ? "" : "Un prénom est obligatoire" }
            error={!isOk(person.firstName)}
          >
            <Input 
              name="firstNamePerson" 
              disabled={true} 
              value={person.firstName}  
              error={!isOk(person.firstName)} 
              className={"bg-slate-200 cursor-not-allowed"}
            />
          </FormItem>

          <FormItem 
            label="Votre numéro de téléphone"
            desc={isOk(person.phoneNumber) ? "" : "Un numéro de téléphone est obligatoire" }
            error={!isOk(person.phoneNumber)}
          >
            <Input 
              name="phoneNumberPerson" 
              disabled={true} 
              value={person.phoneNumber} 
              error={!isOk(person.phoneNumber)}
              className={"bg-slate-200 cursor-not-allowed"}
            />
          </FormItem>

          <FormItem 
            label="Votre email"
            desc={isOk(person.email) ? "" : "Un email est obligatoire" }
            error={!isOk(person.email)}
          >
            <Input 
              name="emailPerson" 
              disabled={true} 
              value={person.email}  
              error={!isOk(person.email)} 
              className={"bg-slate-200 cursor-not-allowed"}
            />
          </FormItem>
        </div>

        <div className="mt-5">
          <div className="text-base mb-2">Adresse de facturation</div>
          {person.address ? 
            <AddressDisplay Address={person.address}/>
          :
          <div className="text-base text-red-500">
            Une adresse est obligatoire.
          </div>
          }
        </div>


      </div>
    );

}

export default DisplayPersonInformations;