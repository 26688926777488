import axios from "axios";
import { FormContextRental } from "FormContextRental";
import { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SendRentalRequestButtonProps {
  onClick?: () => void
}

const SendRentalRequestButton : FC<SendRentalRequestButtonProps>  = ({
  onClick = () => {}
}) => {

  const context = useContext(FormContextRental);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  var history = useHistory();

  const onClickHandler = () => {



    setDisabled(true);
    setError(false);

    const data = {
      startDate: context.dateRange?.startDate,
      endDate: context.dateRange?.endDate,
      rentalAddress: context.addressSelected,
      complementsAddress: context.AddressComplements,
      person: context.contactPerson,
      company: context.contactCompany,
      desiredMaterials: context.desiredMaterials
    }

    axios.post("RentalRequest", data).then((response) => {
      history.push("/materials-rental-step5")
    }).catch((error) => {
      setDisabled(false);
      setError(true);
    });

    onClick();
  }

  return (
    <div>
        <ButtonPrimary onClick={onClickHandler} type={"button"} className="w-full" disabled={disabled}>
          {disabled &&
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Envoi...
            </>
          }
          {!disabled && 
            <>Envoyer ma demande</>
          }
        </ButtonPrimary>   
        {error && 
          <p className="text-red-600 text-center mt-5"><span className="font-bold">Une erreur est survenue...</span><br /> Merci de réessayer ou de nous contacter directement depuis la page de contact.</p>
        }
    </div>
   
  );
}

export default SendRentalRequestButton;