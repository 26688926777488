import ContactInformations from "components/ContactInformations/ContactInformations";
import React, { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import CommonLayout from "./CommonLayout";
import { FormContextRental } from "FormContextRental";

export interface MaterialRentalStep3Props {
  className?: string;
}

const MaterialRentalStep3: FC<MaterialRentalStep3Props> = ({ className = "", }) => {
  const history = useHistory();
  const formContextRental = useContext(FormContextRental);

  const [missingInfos, setMissingInfos] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<boolean>(false);

  if (formContextRental.dateRange == null || formContextRental.dateRange.startDate == null || formContextRental.dateRange.endDate == null) {
    history.push("/materials-rental-step1");
  }

  const NextStep = () => {
    window.scrollTo(0, 0)
    console.log(formContextRental.contactPerson)
    if(
      (formContextRental.contactPerson !== undefined && 
        (
          formContextRental.contactPerson.name !== "" || 
          formContextRental.contactPerson.firstName !== "" || 
          formContextRental.contactPerson.email !== "" || 
          formContextRental.contactPerson.phoneNumber !== ""
        )
      )){
        setMissingInfos(false);
        if(formContextRental.contactPerson.address !== null) {
          setAddressError(false);
          history.push("/materials-rental-step4");
        }else {
          setAddressError(true);
        }

      } else {
        setMissingInfos(true);
      }

  }
  return (
    <CommonLayout
    index="3"
    backtHref="/materials-rental-step2"
    nextHref="/materials-rental-step4"
    contentBlockWidth="3"
    onClick={NextStep}
    >
        <>
          <h2 className="text-2xl font-semibold">Nous avons besoin de vous connaître!</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <p>
            Afin de faciliter les démarches commerciales de votre future location  (devis, factures...), nous avons besoin de vos informations administratives. <br/>
          </p>
          {missingInfos && 
            <p className="text-red-600 text-center"><span className="font-bold">Certaines informations sont manquantes.</span><br /> Nous en avons besoin pour établir votre devis.</p>
          }
          {addressError && 
            <p className="text-red-600 text-center"><span className="font-bold">Merci de saisir votre adresse de facturation.</span></p>
          }
          {/* FORM */}
          <ContactInformations />
        </>
    </CommonLayout>
  );
};

export default MaterialRentalStep3;
