import { Attribute } from "data/types";
import { FC } from "react";

export interface IconAttributeProps {
  className?: string,
  attribute: Attribute
}

const IconAttribute: FC<IconAttributeProps> = ({attribute, className = ""}) => {

  let icon = "lab la-buffer"

  switch (attribute.name) {
    case  attribute.name.match("[p|P]rofondeur.*")?.input:
      icon = "las la-ruler-combined";
      break;
    case attribute.name.match("[l|L]ongueur.*")?.input:
        icon = "las la-ruler-combined";
        break;
    case attribute.name.match("[l|L]argeur.*")?.input:
      icon = "las la-ruler-horizontal";
      break;
    case attribute.name.match("[h|H]auteur.*")?.input:
      icon = "las la-ruler-vertical";
      break;
    case attribute.name.match("[p|P]oid.*")?.input:
      icon = "las la-weight-hanging";
      break;
    case attribute.name.match("[p|P]TAC.*")?.input:
        icon = "las la-weight-hanging";
        break;
    case "Capacité":
      icon = "las la-water";
      break;
    case "Nombre de tasses":
      icon = "las la-coffee";
      break;      
  }

  return (
  <div className={className}>
    <i className={icon + " text-2xl"}></i>
    <span className="">{attribute.name}: {attribute.value}{attribute.unit}</span>
  </div>
  );
}

export default IconAttribute;