import React, { FC, useContext, useState } from "react";
import CommonLayout from "./CommonLayout";
import FormItem from "shared/FormItem/FormItem";
import Input from "shared/Input/Input";
import { FormContextRental } from "FormContextRental";
import AddressSearch from "components/AddressSearch/AddressSearch";
import Textarea from "shared/Textarea/Textarea";
import { Address } from "data/types";
import SelectDateRange from "components/SelectDateRange/SelectDateRange";
import DisplayDateRange from "components/DisplayDateRange/DisplayDateRange";
import { useHistory } from "react-router-dom";

export interface MaterialRentalStep1Props {
  className?: string;
}

const MaterialRentalStep1: FC<MaterialRentalStep1Props> = ({ className = "", }) => {

  const [selectedAddress, setSelectedAddress] = useState<Address>();
  const [dateRangeError, setDateRangeError] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<boolean>(false);
  const formContextRental = useContext(FormContextRental);
  
  const history = useHistory();

  const setAddress = (addressParam: Address) => {
    setSelectedAddress(addressParam);
    formContextRental.setAddressSelected(addressParam);
  }

  const displayStreet = (paramSelectedAddressSate: Address | undefined, paramSelectedAddressContext: Address | null) => {

    const typeDisplay: string[] = ["housenumber", "street"];

    if(paramSelectedAddressSate && (typeDisplay.indexOf(paramSelectedAddressSate.type) > -1)) {
      return (paramSelectedAddressSate.street ? paramSelectedAddressSate.street : paramSelectedAddressSate.name);
    } else if (paramSelectedAddressContext && (typeDisplay.indexOf(paramSelectedAddressContext.type) > -1)){
      return (paramSelectedAddressContext.street ? paramSelectedAddressContext.street : paramSelectedAddressContext.name);
    } else {
      return ("");
    }
  }
  const displayCity = (paramSelectedAddressSate: Address | undefined, paramSelectedAddressContext: Address | null) => {
    if(paramSelectedAddressSate) {
      return (paramSelectedAddressSate.oldcity ? paramSelectedAddressSate.city +" ("+paramSelectedAddressSate.oldcity+")" : paramSelectedAddressSate.city);
    } else if (paramSelectedAddressContext){
      return (paramSelectedAddressContext.oldcity ? paramSelectedAddressContext.city +" ("+paramSelectedAddressContext.oldcity+")" : paramSelectedAddressContext.city);
    } else {
      return ("");
    }
  }

  const displayNumber = (paramSelectedAddressSate: Address | undefined, paramSelectedAddressContext: Address | null) => {
    if(paramSelectedAddressSate) {
      return (
        paramSelectedAddressSate.housenumber ? 
          paramSelectedAddressSate.housenumber : 
          (paramSelectedAddressSate.housenumber ? paramSelectedAddressSate.housenumber : "")

        );
    } else if (paramSelectedAddressContext){
      return (paramSelectedAddressContext.housenumber ? paramSelectedAddressContext.housenumber : "");
    } else {
      return ("");
    }
  }
  
  const NextStep = () => {
    window.scrollTo(0, 0)

    if(formContextRental.dateRange != null && formContextRental.dateRange.startDate != null && formContextRental.dateRange.endDate != null){
      setDateRangeError(false);
      if (formContextRental.addressSelected != null){
        setAddressError(false);
        history.push("/materials-rental-step2");
      }else {
        setAddressError(true);
      }
    } else {
      setDateRangeError(true);
    }
  }

  return (
    <CommonLayout
    index="1"
    backtHref="/materials-rental-step1"
    nextHref="/materials-rental-step2"
    contentBlockWidth="4"
    onClick={NextStep}
    >
      <FormContextRental.Consumer>
        {formValues => 
          <>
            <h2 className="text-2xl font-semibold">Confirmez-nous votre événement!</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <p>Afin de nous permettre de répondre au mieux à votre besoin, nous devons connaître le lieu et la période de votre événement.</p>
            <p><span className="font-bold">Avis de non-engagement</span> : Veuillez noter que le présent formulaire a pour but de recueillir des informations pour l'établissement d'un devis et la planification d'une prestation. Remplir ce formulaire ne constitue en aucun cas un engagement contractuel. Vous n'êtes pas lié(e) par cette demande et pouvez choisir de ne pas procéder avec nos services.</p>

            <h3 className="text-lg font-medium">Selectionnez la période de votre événement</h3>
            {dateRangeError && 
              <p className="text-red-600 text-center"><span className="font-bold">Merci de sélectionner la période de votre événement.</span></p>
            }
            <p className="text-neutral-400" style={{margin: "0px"}}>Si votre événement se déroule sur une seule journée, merci de cliquer deux fois sur le même jour.</p>
            <SelectDateRange  dateRange={formValues.dateRange} setDateRangeValue={formValues.setDateRange} className="grid justify-items-center" />
            <DisplayDateRange dateRange={formValues.dateRange} className="grid justify-items-center"  />
            
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <h3 className=" mt-5 text-lg font-medium">Recherchez le lieu de votre événement</h3>
            <p>Saisissez l'adresse et sélectionnez là ensuite parmi les propositions. <br/> Si l'adresse n'est pas répertoriée, saisissez une adresse proche.</p>
            {/* FORM */}
            {addressError && 
              <p className="text-red-600 text-center"><span className="font-bold">Merci de saisir l'adresse de votre événement.</span></p>
            }
            <AddressSearch inError={addressError} rawAddress={formValues.location} address={formValues.addressSelected} handleAddress={setAddress} label="Adresse livraison" desc="Tapez votre adresse ici afin de pouvoir nous aider à localiser votre évémenent" />
            {(selectedAddress || formValues.addressSelected) && 
              <>
                <h3 className="text-2xl font-semibold">Adresse prise en compte</h3>
                <div className="grid grid-cols-6 gap-6">
                    <FormItem
                      label="Numéro"
                      className="col-span-1"
                    >
                      <Input
                        value={displayNumber(selectedAddress, formValues.addressSelected)}
                        defaultValue=""
                        className={"bg-slate-200 cursor-not-allowed"}
                        type="text"
                        disabled
                        readOnly
                      />
                    </FormItem>

                    <FormItem
                      label="Rue"
                      className="col-span-5"
                    >
                      <Input
                        value={displayStreet(selectedAddress, formValues.addressSelected)}
                        defaultValue=""
                        className={"bg-slate-200 cursor-not-allowed"}
                        disabled
                        readOnly
                      />
                    </FormItem>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <FormItem
                      label="Code postal"
                      className="col-span-1"
                    >
                      <Input
                        value={selectedAddress?.postcode ? selectedAddress.postcode : formValues.addressSelected?.postcode }
                        className={"bg-slate-200 cursor-not-allowed"}
                        defaultValue=""
                        disabled
                        readOnly
                      />
                    </FormItem>

                    <FormItem
                      label="Ville"
                      className="col-span-5"
                    >
                      <Input
                        value={displayCity(selectedAddress, formValues.addressSelected)}
                        defaultValue=""
                        className={"bg-slate-200 cursor-not-allowed"}
                        disabled
                        readOnly
                      />
                    </FormItem>
                  </div>
                  <FormItem label="Département/Région">
                    <Input
                      value={selectedAddress?.context ? selectedAddress.context : formValues.addressSelected?.context }
                      defaultValue=""
                      className={"bg-slate-200 cursor-not-allowed"}
                      disabled
                      readOnly
                    />

                  </FormItem>
                  <FormItem
                    label="Compléments d'adresse"
                    desc="Non obligatoire"
                    >
                      <Textarea
                        cols={3}
                        onChange={(s) => formValues.setAddressComplements(s.currentTarget.value)}
                        value={formValues.AddressComplements}
                        required
                      />
                  </FormItem>
              </>
            }
          </>
        }
      </FormContextRental.Consumer>
    </CommonLayout>
  );
};

export default MaterialRentalStep1;
