import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Material } from "data/types";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";


export interface EquipmentCardProps {
  className?: string;
  data: Material;
  defaultQuantity: number;
  displayQuantity?: boolean,
  displayMoreButton?: boolean,
  displaySelectedQuantity?: boolean,
  size?: "default" | "small";
  updateDesiredMaterial: (quantity: number, material: Material) => void
}

const EquipmentCard: FC<EquipmentCardProps> = ({
  updateDesiredMaterial,
  size = "default",
  className = "",
  defaultQuantity = 0,
  displayQuantity = true,
  displayMoreButton = false,
  displaySelectedQuantity =  true,
  data,
}) => {
  const {
    // picture,
    available_quantity,
    description,
    name,
    id,
  } = data;

  const [selected, setSelected] = useState<boolean>(false);

  const onChangeCard = (quantity: number) => {
    if (quantity > 0) {
      setSelected(true);
    } else {
      setSelected(false);
    }
    updateDesiredMaterial(quantity, data);
  }

  //TODO: à retirer
  const tempimg: string[] = [];
  tempimg.push( process.env.REACT_APP_API_URL + `/Materials/${data.id}/image`);

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={tempimg}
        />
      </div>
    );
  };

  const borderColor = (unavailable = false, selected = false) => {

    if(unavailable) {
      return "border-red-500";
    }

    if (selected) {
      return "border-green-500";
    }

    return "border-neutral-100";

  }

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-0">
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="">{name}</span>
              {/* <span className="line-clamp-1">{name}</span> */}
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-0">
            <span className="line-clamp-1">{description}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>

        {displaySelectedQuantity && 
          <div className="flex justify-between items-center">
            {available_quantity !== 0 ? 
              <NcInputNumber label="Quantité" defaultValue={defaultQuantity} max={available_quantity} onChange={(q) => onChangeCard(q)} /> 
              : <Badge name="Déjà loué(s) !" color="red" />
            }
          </div>
        }

        {displayQuantity && 
          <div className="flex justify-between items-center">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {available_quantity} disponible(s)
            </span>
          </div>
        }

        {displayMoreButton && 
          <div className="flex justify-center items-center">
            <ButtonSecondary href={"/material?id="+id} targetBlank >Voir le produit</ButtonSecondary>
          </div>
        }

      </div>
    );
  };

  return (
    <div 
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border dark:border-neutral-800 ${borderColor(available_quantity === 0, selected)} rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
        {renderSliderGallery()}
        {renderContent()}
    </div>
  );
};

export default EquipmentCard;
