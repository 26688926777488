import { Address, Company, DateRange, DesiredMaterial, Person } from "data/types";
import moment from "moment";
import React, { Dispatch, SetStateAction } from "react";

export interface EquipmentRentalFormValues {
  // ### Event informations ### //
  dateRange: DateRange | undefined,
  location: string,
  addressSelected: Address | null,
  AddressComplements: string,
  setAddressSelected: Dispatch<SetStateAction<Address| null>>,
  setDateRange: Dispatch<SetStateAction<DateRange>>,
  setLocation: Dispatch<SetStateAction<string>>,
  setAddressComplements: Dispatch<SetStateAction<string>>,

  // ### Materials informations ### //
  desiredMaterials: DesiredMaterial[],
  setDesiredMaterials: Dispatch<SetStateAction<DesiredMaterial[]>>,

  // ### Contact informations ### //
  contactPerson: Person | undefined,
  contactCompany: Company | null
  setContactPerson: Dispatch<SetStateAction<Person|undefined>>,
  setContactCompany: Dispatch<SetStateAction<Company|null>>,
}

export const defaultValuesFormContextRental : EquipmentRentalFormValues = {
  dateRange: {
    startDate: moment(),
    endDate: null,
  },
  location: "",
  addressSelected: null,
  AddressComplements: "",
  setAddressSelected: () => {},
  setDateRange: () => {},
  setLocation:  () => {},
  setAddressComplements: () => {},
  desiredMaterials:[],
  setDesiredMaterials: () =>{},
  contactPerson: undefined,
  setContactPerson: () => {},
  contactCompany: null,
  setContactCompany: () =>{}
}

export const FormContextRental =  React.createContext(defaultValuesFormContextRental);