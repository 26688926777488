import React from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommonLayoutProps {
  index: string;
  nextHref: string;
  backtHref: string;
  nextBtnText?: string;
  children: React.ReactNode;
  contentBlockWidth?: string;
  onClick: ()=> void;
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "1",
  children,
  nextHref,
  nextBtnText,
  backtHref,
  contentBlockWidth="3",
  onClick,
}) => {

  return (
    <form>
      <div
        className={`nc-PageAddListing1 px-4 max-w-`+contentBlockWidth+`xl mx-auto pb-24 pt-5 sm:py-18 lg:pb-32`}
        data-nc-id="PageAddListing1"
      >
        <div className="space-y-11">
          <div>
            <span className="text-4xl font-semibold">{index}</span>{" "}
            <span className="text-lg text-neutral-500 dark:text-neutral-400">
              / 5
            </span>
          </div>

          {/* --------------------- */}
          <div className="listingSection__wrap ">{children}</div>

          {/* --------------------- */}
          {!["4", "5"].includes(index) && 
            <div className="flex justify-end space-x-5">
              <ButtonSecondary href={backtHref}>Précédent</ButtonSecondary>
              <ButtonPrimary onClick={onClick} type="button">
                {nextBtnText || "Suivant"}
              </ButtonPrimary>
            </div>
          }
        </div>
      </div>
    </form>
  );
};

export default CommonLayout;
