import React, { FC, useEffect, useState } from "react";
import StartRating from "components/StartRating/StartRating";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import axios from "axios";
import { Material } from "data/types";
import IconAttribute from "./IconAttribute";

export interface MaterialDetailPageProps {
  className?: string;
}

const MaterialDetailPage: FC<MaterialDetailPageProps> = ({ className = ""}) => {
  const { id } = Object.fromEntries(new URLSearchParams(window.location.search));

  // const { id } = useParams<{id: string}>();

  const [material, setMaterial] = useState<Material>();
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    axios.get("Materials/"+id).then((result) => {
      setMaterial(result.data.result)
      setLoaded(true);
    });

  }, [loaded]);
  
    const renderSection1 = () => {
      return (
        <div className="listingSection__wrap !space-y-6">
          {/* 1 */}
          <div className="flex justify-between items-center">
            <Badge color="green" name="Prêt pour la location" />
            <LikeSaveBtns />
          </div>
  
          {/* 2 */}
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {material?.name}
          </h2>
  
          {/* 4 */}
          <div className="flex items-center">
            <p>{material?.description}</p>
          </div>
  
          {/* 5 */}
          <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
  
          {/* 6 */}
          <div className="grid sm:grid-cols-3 grid-cols-1 text-sm text-neutral-700 dark:text-neutral-300">
            {material?.attributes.map((attribute, i) => {
              return (
                <IconAttribute key={i} className="flex items-center space-x-3" attribute={attribute}/>
              );
            })}
          </div>
        </div>
      );
    };
  
    if (!loaded) {
      return (

          <div className="flex justify-center items-center h-100">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>Chargement ...</span>
          </div>
      );
    }
    return (
      <div
        className={`nc-ListingStayDetailPage  ${className}`}
        data-nc-id="ListingStayDetailPage"
      >
        {/* SINGLE HEADER */}
        <>
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            <div className="relative grid place-items-center">

                <NcImage
                  containerClassName=""
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  style={{maxHeight: "300px"}}
                  src={process.env.REACT_APP_API_URL + `/Materials/${id}/image`}
                />
                {/* <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div> */}
            </div>
          </header>
        </>
  
        {/* MAIn */}
        <main className="container relative z-10 mt-11 flex flex-row ">
          {/* CONTENT */}
          <div className="w-full space-y-8">
            {renderSection1()}
          </div>

        </main>
        <div className="container grid justify-items-center pt-8 pb-8 mb-5">
          <ButtonPrimary href="/materials-rental-step1">Commencer une réservation</ButtonPrimary>
        </div>


      </div>
    );
}

export default MaterialDetailPage;