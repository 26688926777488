import { FieldInputProps, FieldMetaProps, FormikProps, getIn } from "formik";
import { FC } from "react";
import FormItem, { FormItemProps } from "shared/FormItem/FormItem";
import Input, { InputProps } from "shared/Input/Input"

export interface FormikTailWildInputProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  meta: FieldMetaProps<any>;
  formItemProps: FormItemProps | null,
  inputProps: InputProps | null
  className?: string,
  customOnChange?: (value: any) => void
}

const FormikTailWildInput : FC<FormikTailWildInputProps> = ({
  field,
  form,
  formItemProps,
  inputProps,
  className,
  customOnChange = (value:any) => {}
  // form: {touched: boolean, errors: string},
}) => {
  return (
    <>
      <FormItem
      {...formItemProps}
      desc={getIn(form.touched, field.name) && getIn(form.errors, field.name) ? getIn(form.errors, field.name) : ""}
      error={getIn(form.touched, field.name) && getIn(form.errors, field.name)}
      className={className}
      >
        <Input {...field} {...inputProps} error={getIn(form.touched, field.name) && getIn(form.errors, field.name)} onChange={(e) => {field.onChange(e); customOnChange(e) }}   />
          {/* {getIn(form.touched, field.name) && getIn(form.errors, field.name) ? <p>{getIn(form.errors, field.name)}</p> : ""} */}
      </FormItem>

    </>

  )

}

export default FormikTailWildInput;