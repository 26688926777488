import { Address } from "data/types";
import { Dispatch, FC } from "react";

export interface AddressRenderProps {
  addressToRender: Address[]
  handleSelectLocation: Dispatch<Address>
  isOpen: (bool: boolean) => void
}

const AddressRender: FC<AddressRenderProps> = ({addressToRender, handleSelectLocation, isOpen}) => {

  const handleclick = (addr: Address) => {
    isOpen(false);
    handleSelectLocation(addr);
  }

  if(addressToRender.length === 0) {
    return (
      <span
        className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700b cursor-wait"
       >
        <span className="block text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 sm:h-6 sm:w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </span>
        <span className="block font-medium text-neutral-700 dark:text-neutral-200">
          Aucune correspondance
        </span>
      </span>
    )
  }

  return (
    <>
      {addressToRender.map((address) => (
        <span
          onClick={() => handleclick(address)}
          key={address.id}
          className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
        >
          <span className="block text-neutral-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 sm:h-6 sm:w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </span>
          <span className="block font-medium text-neutral-700 dark:text-neutral-200">
            {address.label}
          </span>
        </span>
      ))}
    </>
  );
}

export default AddressRender;