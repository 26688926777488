import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.jpg";
import logoLightImg from "images/logo.jpg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block ${className}`}
      style={{textDecoration: "none"}}
    >

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {/* {img ? (
        <img
          className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
      <div className="dark:hidden">
        <span className="font-bold text-4xl" style={{color: "rgb(225, 175, 85)", fontFamily: "ArmyBuster"}}>H2R</span> <span className="font-bold text-2xl" style={{color: "black"}}>EVENT</span>
      </div>
      <div className="hidden dark:block">
        <span className="font-bold text-4xl" style={{color: "rgb(225, 175, 85)", fontFamily: "ArmyBuster"}}>H2R</span> <span className="font-bold text-2xl" style={{color: "white"}}>EVENT</span>
      </div>
    </Link>
  );
};

export default Logo;
