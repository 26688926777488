import React, { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import { Range } from "rc-slider";
// import convertNumbThousand from "utils/convertNumbThousand";
import { Category } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";

export interface TabFiltersProps {
  categories: Category[],
  categoriesUsed: number[],
  subCategoriesUsed: number[],
  updateCategoryUsed: (id: number) => void,
  updateSubCategory: (id: number) => void
}

const TabFilters : FC<TabFiltersProps> = ({categories, categoriesUsed, subCategoriesUsed,  updateCategoryUsed, updateSubCategory}) => {
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  const [rangePrices, setRangePrices] = useState([0, 1000]);

  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);
  //
  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  
  const renderTabsFilterItem = () => {
    const isUsed = (id: number) => {
      const i = categoriesUsed.findIndex(idUsed => idUsed === id);
      if(i > -1){
        return true;
      } else {
        return false;
      }
    };

    const renderButtonStyle = (id: number) => {
      return isUsed(id) ? `!text-primary-700 !border-primary-500 bg-primary-50` : `!border-neutral-300`
    }

    return (
      <>
      {categories.map((cat, i) => {
        return (
          <div key={i} onClick={(e) => updateCategoryUsed(cat.id)}>
            <div className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer ${renderButtonStyle(cat.id)}`}>
              <span>{cat.name}</span>
              { isUsed(cat.id) ? renderXClear() : <></>}
            </div>
          </div>
        )
      })}

      </>
    );
  }

  const renderMoreFilterItem = (
    categoriesToDisplay: Category[]
  ) => {

    const cat = categoriesToDisplay.filter(c => c.name.toLocaleLowerCase().trim() !== "indisponibles");

    if(cat.length ===  0) return <></>;

    const list1 = cat.filter((_, i) => i < cat.length / 2);
    const list2 = cat.filter((_, i) => i >= cat.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.id}
              name={item.name}
              label={item.name}
              onChange={() => updateSubCategory(item.id)}
              defaultChecked={subCategoriesUsed.findIndex(subCatId => subCatId === item.id) > -1}
              // defaultChecked={!!item.defaultChecked}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.id}
              name={item.name}
              label={item.name}
              onChange={() => updateSubCategory(item.id)}
              defaultChecked={subCategoriesUsed.findIndex(subCatId => subCatId === item.id) > -1}
              // defaultChecked={!!item.defaultChecked}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderTabMoreFilter = () => {
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilter}
        >
          <span>Plus de filtres</span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Plus de filtres
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {categories.map((cat, i) => {
                        if (cat.sub_categories != null && cat.sub_categories.length > 0) {
                          return (
                            <div className="py-7">
                            <h3 className="text-xl font-medium">{cat.name}</h3>
                            <div className="mt-6 relative ">
                              {renderMoreFilterItem(cat.sub_categories)}
                            </div>
                          </div>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Tout afficher
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Appliquer
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const renderTabMoreFilterMobile = () => {
    return (
      <div>
        <div
          className={`flex lg:hidden items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilterMobile}
        >
          <span>Filtres</span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Filtres
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* ---- */}

                      {categories.map((category, i) => (
                          <div className="py-7" key={i}>
                            <h3 className="text-xl font-bold">
                              <Checkbox
                                  key={category.id+"_cat"+i}
                                  name={category.name}
                                  label={category.name}
                                  className="text-xl font-bold"
                                  onChange={() => updateCategoryUsed(category.id)}
                                  defaultChecked={categoriesUsed.findIndex(cattId => cattId === category.id) > -1}
                                />
                            </h3>
                            <div className="mt-6 relative flex flex-col space-y-5">
                              {category.sub_categories?.map((subCategory, i) => (
                                <Checkbox
                                  key={subCategory.id+"_"+i}
                                  name={subCategory.name}
                                  label={subCategory.name}
                                  onChange={() => updateSubCategory(subCategory.id)}
                                  defaultChecked={subCategoriesUsed.findIndex(subCatId => subCatId === subCategory.id) > -1}
                                  // defaultChecked={!!item.defaultChecked}
                                />
                              ))}
                            </div>
                          </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonPrimary
                      onClick={closeModalMoreFilterMobile}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Appliquer
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };
// TODO: faire la version mobile
  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderTabsFilterItem()}
        {renderTabMoreFilter()}
      </div>
      {renderTabMoreFilterMobile()}
    </div>
  );
};

export default TabFilters;
