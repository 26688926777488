import React, { useContext, useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import ButtonSubmit from "./ButtonSubmit";
import moment from "moment";
import { FC } from "react";
import { FormContextRental } from "../../FormContextRental";
import { Address, DateRange } from "data/types";

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "";
const defaultDateRange = {
  startDate: moment(),
  endDate: null
};

const StaySearchForm: FC<StaySearchFormProps> = ({  haveDefaultValue = false,}) => {
  const [dateRangeValue, setDateRangeValue] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });
  const [locationInputValue, setLocationInputValue] = useState("");
  const [addressSelectedRental, setAddressSelectedRental] = useState<Address | null>(null);

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );

  const formValue = useContext(FormContextRental);

  const submitHandler = () => {
    formValue.setLocation(locationInputValue);
    formValue.setDateRange(dateRangeValue);
    formValue.setAddressSelected(addressSelectedRental);
  }

  //
  useEffect(() => {
    if (haveDefaultValue) {
      setDateRangeValue(defaultDateRange);
      setLocationInputValue(defaultLocationValue);
    }
  }, []);
  //

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex flex-col md:flex-row  rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0">
        <FormContextRental.Consumer>
          {formValues => (
            <>
              <LocationInput
                defaultValue={formValues.location}
                onChange={(e) => setLocationInputValue(e)}
                addressSelected={(a: Address) => setAddressSelectedRental(a)}
                onInputDone={() => setDateFocused("startDate")}
              />
              <StayDatesRangeInput
                defaultValue={formValues.dateRange!}
                defaultFocus={dateFocused}
                onChange={(data) => setDateRangeValue(data)}
              />
            </>
          )}
        </FormContextRental.Consumer>

        {/* BUTTON SUBMIT OF FORM */}
        <div className="px-4 py-4 lg:py-0 flex items-center justify-center">
          <ButtonSubmit callback={submitHandler} />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
