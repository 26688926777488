import AddressDisplay from "components/AddressSearch/AddressDisplay";
import DisplayCompanyInformations from "components/ContactInformations/DisplayCompanyInformations";
import DisplayPersonInformations from "components/ContactInformations/DisplayPersonInformations";
import { FormContextRental } from "FormContextRental";
import moment from "moment";
import React, { FC } from "react";
import CommonLayout from "./CommonLayout";

export interface MaterialRentalStep5Props {
  className?: string;
}

const MaterialRentalStep5: FC<MaterialRentalStep5Props> = ({ className = "", }) => {

  const NextStep = () => {
    // const history = useHistory();
    // history.push("/materials-rental-step2");
  }
  return (
    <CommonLayout
    index="5"
    backtHref="/materials-rental-step3"
    nextHref=""
    contentBlockWidth="3"
    onClick={NextStep}
    >
        <>
          <h2 className="text-2xl font-semibold">C'est envoyé 🎉</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <p>
            Vous allez recevoir dans quelques instants un mail de confirmation. N'hésitez pas à répondre à celui-ci pour compléter et/ou modifier des informations.<br/> <br />
            Si vous ne le recevez pas rapidement, après avoir vérifié dans vos spams, vous pouvez nous contacter directement à l'adresse suivante: <a href="mailto:h2r.event@gmail.com">h2r.event@gmail.com</a> <br /> <br />
            Cette démarche vous permet de recevoir un devis pour votre événement, vous n'êtes en aucun cas engagé.
          </p>
          {/* FORM */}
          <FormContextRental.Consumer>
            {values => 
            <>
              <h3 className="text-lg font-semibold">Date(s) de votre événement</h3>

                <div>
                  <div className="border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                    <div className="flex-1 p-5 flex space-x-4">
                      <svg
                        className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                          stroke="#D1D5DB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <div className="flex flex-col">
                        <span className="text-sm text-neutral-400">
                          {values.dateRange!.startDate === values.dateRange!.endDate ? 
                            "Date" : "Date de début"
                          }
                        </span>
                        <span className="mt-1.5 text-lg font-semibold">
                          {moment(values.dateRange!.startDate).format("Do MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                    {values.dateRange!.startDate !== values.dateRange!.endDate &&
                      <div className="flex-1 p-5 flex space-x-4">
                        <svg
                          className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                            stroke="#D1D5DB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
  
                        <div className="flex flex-col">
                          <span className="text-sm text-neutral-400">Date de fin</span>
                          <span className="mt-1.5 text-lg font-semibold">{moment(values.dateRange!.endDate).format("Do MMMM YYYY")}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              <h3 className="text-lg font-semibold">Adresse de livraison</h3>
              <AddressDisplay Address={values.addressSelected} /> 

              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              
              <h3 className="text-lg font-semibold">Votre besoin en matériel(s)</h3>
              <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
                {values.desiredMaterials.map((dmatp) => (
                  <>
                    <div className={`nc-Card3Small grid grid-flow-row-dense grid-cols-4 `}>
                      <h2 className="nc-card-title block col-span-3 mb-2 mt-2">
                        - <span className="text-base  font-semibold text-neutral-900 dark:text-neutral-100">{dmatp.material.name}</span> <span className="text-sm italic font-light">({dmatp.material.reference})</span>
                      </h2>
                      <div className="relative space-y-2 mb-2 mt-2">
                        x {dmatp.quantity}
                      </div>
                    </div>
                  </>
                  ))
                }
              </div> 

              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              
              <h3 className="text-lg font-semibold">Vos informations de facturations</h3>
              {values.contactCompany &&
                <DisplayCompanyInformations company={values.contactCompany} />
              }
              {values.contactPerson &&
                <DisplayPersonInformations person={values.contactPerson} />
              }
            </>
            }
          </FormContextRental.Consumer>
        </>
    </CommonLayout>
  );
};

export default MaterialRentalStep5;
