import { Company } from "data/types";
import { FC } from "react";
import FormItem from "shared/FormItem/FormItem";
import Input from "shared/Input/Input";

export interface DisplayCompanyInformationsProps {
  company: Company | null | undefined
}

const DisplayCompanyInformations: FC<DisplayCompanyInformationsProps> = ({company}) => {
  
  if(!company) {
    return(<></>);
  }
  
  const isOk = (value: string | null | undefined) => {
    if(!value) {
      return false;
    } else if (value.replace(" ", "") === "") {
      return false;
    }
    return true;
  }
  
  return (
    <div>
          <FormItem 
            label="Nom de l'entreprise"
            desc={isOk(company.name) ? "" : "Un nom est obligatoire" }
            error={!isOk(company.name)}
            className={"mt-4 ml-4"}
          >
            <Input 
              name="name" 
              disabled={true} 
              value={company.name}  
              error={!isOk(company.name)} 
              className={"bg-slate-200 cursor-not-allowed"}
            />
          </FormItem>
          <FormItem 
            label="Siret de l'entreprise"
            desc={isOk(company.siret) ? "" : "Un siret est obligatoire" }
            error={!isOk(company.siret)}
            className={"mt-4 ml-4"}
          >
            <Input 
              name="siret" 
              value={company.siret} 
              disabled={true} 
              error={!isOk(company.siret)}
              className={"bg-slate-200 cursor-not-allowed"}
            />
          </FormItem>
          <FormItem
            label="Email de l'entreprise"
            desc={isOk(company.email) ? "" : "Un email est obligatoire" }
            error={!isOk(company.email)}
            className={"mt-4 ml-4"}
          >
            <Input 
              name="email" 
              value={company.email} 
              disabled={true} 
              error={!isOk(company.email)}
              className={"bg-slate-200 cursor-not-allowed"}
            />
          </FormItem>
          <FormItem
            label="Téléphone de l'entreprise"
            desc={isOk(company.phone) ? "" : "Un numéro de téléphone est obligatoire" }
            error={!isOk(company.phone)}
            className={"mt-4 ml-4"}
          >
            <Input 
              name="phone" 
              value={company.phone} 
              disabled={true} 
              error={!isOk(company.phone)}
              className={"bg-slate-200 cursor-not-allowed"}
            />
          </FormItem>
    </div>
  );
}

export default DisplayCompanyInformations;