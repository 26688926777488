import React, { FC } from "react";
import "react-dates/initialize";
import StaySearchForm from "./StaySearchForm";

export interface RentailSearchFormProps {
  className?: string;
}

const RentailSearchForm: FC<RentailSearchFormProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      <StaySearchForm haveDefaultValue={true} />
    </div>
  );
};

export default RentailSearchForm;
