import AddressDisplay from "components/AddressSearch/AddressDisplay";
import DisplayCompanyInformations from "components/ContactInformations/DisplayCompanyInformations";
import DisplayPersonInformations from "components/ContactInformations/DisplayPersonInformations";
import { FormContextRental } from "FormContextRental";
import moment from "moment";
import React, { FC } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import SendRentalRequestButton from "./SendRentalRequestButton";

export interface MaterialRentalStep4Props {
  className?: string;
}

const MaterialRentalStep4: FC<MaterialRentalStep4Props> = ({ className = "", }) => {

  const errorMessage = (message: string) => {
    return(
      <div className="text-base text-red-500">
        {message}
      </div>
    );
  }

  const NextStep = () => {
    // const history = useHistory();
    // history.push("/materials-rental-step2");
  }

  return (
    <CommonLayout
    index="4"
    backtHref="/materials-rental-step3"
    nextHref=""
    contentBlockWidth="3"
    onClick={NextStep}
    >
        <>
          <h2 className="text-2xl font-semibold">Récapitulatif de votre demande!</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <p>
            Vérifiez que les informations saisies sont correctes puis validez.<br/>
          </p>
          {/* FORM */}
          <FormContextRental.Consumer>
            {values => 
            <>
              <h3 className="text-lg font-semibold">Date(s) de votre événement</h3>
              {values.dateRange ?
                <div>
                  <div className="border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                    <div className="flex-1 p-5 flex space-x-4">
                      <svg
                        className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                          stroke="#D1D5DB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <div className="flex flex-col">
                        <span className="text-sm text-neutral-400">
                          {values.dateRange.startDate === values.dateRange.endDate ? 
                            "Date" : "Date de début"
                          }
                        </span>
                        <span className="mt-1.5 text-lg font-semibold">
                          {moment(values.dateRange!.startDate).format("Do MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                    {values.dateRange.startDate !== values.dateRange.endDate &&
                      <div className="flex-1 p-5 flex space-x-4">
                        <svg
                          className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                            stroke="#D1D5DB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
  
                        <div className="flex flex-col">
                          <span className="text-sm text-neutral-400">Date de fin</span>
                          <span className="mt-1.5 text-lg font-semibold">{moment(values.dateRange!.endDate).format("Do MMMM YYYY")}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                :
                errorMessage("Aucune date(s) de renseignée(s).")
              }
              <h3 className="text-lg font-semibold">Adresse de livraison</h3>
              {values.addressSelected ?
                <AddressDisplay Address={values.addressSelected} /> :
                errorMessage("Aucune adresse de livraison renseignée.")
              }
              <div className="flex">
                <ButtonSecondary href={"/materials-rental-step1"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg> Modifier la date et le lieu
                </ButtonSecondary>
              </div>

              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              
              <h3 className="text-lg font-semibold">Votre besoin en matériel(s)</h3>
              <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
                {values.desiredMaterials.map((dmatp) => (
                  <>
                    <div className={`nc-Card3Small grid grid-flow-row-dense grid-cols-4 `}>
                      <h2 className="nc-card-title block col-span-3 mb-2 mt-2">
                        - <span className="text-base  font-semibold text-neutral-900 dark:text-neutral-100">{dmatp.material.name}</span> <span className="text-sm italic font-light">({dmatp.material.reference})</span>
                      </h2>
                      <div className="relative space-y-2 mb-2 mt-2">
                        x {dmatp.quantity}
                      </div>
                    </div>
                  </>
                  ))
                }
              </div>
              {values.desiredMaterials.length === 0 &&
                errorMessage("Vous n'avez besoin de rien ?")
              }              
              <div className="flex">
                <ButtonSecondary href={"/materials-rental-step2"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg> Modifier la liste de matériel
                </ButtonSecondary>
              </div>

              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              
              <h3 className="text-lg font-semibold">Vos informations de facturation</h3>
              {values.contactCompany &&
                <DisplayCompanyInformations company={values.contactCompany} />
              }
              {values.contactPerson ?
                <DisplayPersonInformations person={values.contactPerson} />
                :
                errorMessage("Aucun contact renseigné.")
              }

              <div className="flex">
                <ButtonSecondary href={"/materials-rental-step3"}>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg> Modifier les informations de facturation
                </ButtonSecondary>
              </div>
            </>
            }
          </FormContextRental.Consumer>
          <p>
            Nous souhaitons vous informer que les données personnelles que vous nous communiquez dans le cadre de la réalisation de devis pour notre entreprise sont stockées dans notre système informatique. Nous prenons les mesures nécessaires pour garantir la sécurité de ces données et nous respectons les dispositions de la Règlementation Générale sur la Protection des Données (RGPD). <br/>
            <br />
            Si vous avez des questions ou si vous souhaitez exercer vos droits en matière d'accès, de rectification et de suppression de vos données personnelles, n'hésitez pas à nous contacter. <br />
            <br/>
            Nous vous remercions de votre confiance.
          </p>
          <SendRentalRequestButton />
        </>
    </CommonLayout>
  );
};

export default MaterialRentalStep4;
