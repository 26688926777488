import FormikTailWildInput from "components/FormikTailwild/FormikTailWildInput";
import { Company } from "data/types";
import { Field, Formik } from "formik";
import { FC, useState } from "react";
import * as Yup from "yup"

export interface CompanyInformationsProps {
  handleCompany?: (company: Company) => void
  defaultValues?: Company | null
}

const CompanyInformations : FC<CompanyInformationsProps> = ({
  handleCompany = (company: Company) => {},
  defaultValues,
}) => {

  const CompanyValidation = Yup.object().shape({
    name: Yup.string()
              .required("Nom de l'entreprise obligatoire"),
    siret: Yup.string()
              .required("Numéro de siret obligatoire")  
              .max(14, "Un siret comporte 14 caractère maximum"),
    email: Yup.string()
              .required("L'email de l'entreprise est obligatoire.")  
              .email("Votre adresse n'est pas valide"),
    phone: Yup.string()
  });

  const company: Company = {
    id: null,
    name: '',
    siret: '',
    phone: '',
    email: '',
  }
  const [companyState, setCompanyState] = useState(defaultValues ? defaultValues : company);

  const handleOnChangeCompany = (pcompany: Company) => {
    handleCompany(pcompany);
    setCompanyState(pcompany);
  }

  return (
    <>
      <Formik
        initialValues={defaultValues ? defaultValues : company}
        validationSchema={CompanyValidation}
        onSubmit={(value) => {
        }}
      >
        <div>
          <h3>Informations de l'entreprise</h3>
          <div className="flex flex-col space-y-4 space-x-4">
            <Field 
              component={FormikTailWildInput} 
              name="name" 
              formItemProps={{label: "Nom de l'entreprise"}} 
              className={"mt-4 ml-4"}
              customOnChange={(value:any) => {
                companyState.name = value.currentTarget.value
                handleOnChangeCompany(companyState)
              }}
            />
            <Field 
              component={FormikTailWildInput} 
              name="siret" 
              formItemProps={{label: "Siret"}} 
              customOnChange={(value:any) => {
                companyState.siret = value.currentTarget.value
                handleOnChangeCompany(companyState)
              }}
            />
            <Field 
              component={FormikTailWildInput} 
              name="emailCompany" 
              formItemProps={{label: "Email de l'entreprise"}} 
              customOnChange={(value:any) => {
                companyState.email = value.currentTarget.value
                handleOnChangeCompany(companyState)
              }}
            />
            <Field 
              component={FormikTailWildInput} 
              name="phoneCompany" 
              formItemProps={{label: "Numéro de téléphone de l'entreprise"}} 
              customOnChange={(value:any) => {
                companyState.phone = value.currentTarget.value
                handleOnChangeCompany(companyState)
              }}
            />
          </div>
        </div>
      </Formik>
    </>
  );
}

export default CompanyInformations;