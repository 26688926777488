import FormItem from "shared/FormItem/FormItem"
import { FC, useEffect, useRef, useState } from "react"
import Input from "shared/Input/Input"
import axios from "axios"
import AddressRender from "./AddressRender"
import { Address } from "data/types"


export interface AddressSearchProps {
  rawAddress: string
  handleAddress: (addr: Address) => void
  address?: Address | null,
  autoFocus?: boolean,
  label?: string
  desc?: string
  inError?: boolean
}

const AddressSearch: FC<AddressSearchProps> = (
  {
    handleAddress,
    rawAddress = "",
    address = null,
    autoFocus = false,
    label,
    desc = "",
    inError = false,
  }) => {

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [addressToSearch, setAddressToSearch] = useState<Address[]>([]);
  const [showPopover, setShowPopover] = useState<boolean>(autoFocus);
  const [inputAddress, setInputAddress] = useState(rawAddress);

  const [timer, setTimer] = useState(setTimeout(() => {}));

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);
  
  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const callBackSelectAddress = (addressparam: Address) => {
    setInputAddress(addressparam.label);
    handleAddress && handleAddress(addressparam)
    setShowPopover(false);
  }

  const searchAddress = (inputAddress: string) => {
    if(inputAddress.replace(" ", "") !== "") {
      const apiAddress = new URL('https://api-adresse.data.gouv.fr/search/');
      apiAddress.searchParams.append('q', inputAddress);
  
      let citySearchResult: Address[] = [];
  
      axios.get(apiAddress.href).then((response) => {
        if (response.status === 200) {
          response.data.features.map((resultSearch: 
            { properties: Address; }) => (
              citySearchResult.push(resultSearch.properties)
          ));
          setAddressToSearch(citySearchResult);
        }
      });
    }
  }

  useEffect(() => {  
    if (rawAddress !== "" && address === null) {
      searchAddress(rawAddress);
      setShowPopover(true);
    }
  }, [rawAddress])

  const handleOnChangeInputAddress = (value: string) => {
    setInputAddress(value);

    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      searchAddress(value);
    }, 250)

    setTimer(newTimer)
  }

  let errorClass = "";
  if(inError) {
    errorClass = "border-red-600";
  }

  return (

    <div className="relative" ref={containerRef}> 
      <div 
      onClick={() => setShowPopover(true)}
        className={`relative [ nc-hero-field-padding ]  w-100 cursor-pointer    ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
        >
          <FormItem
            label={label ? label : "Saisissez une adresse"}
            desc={desc}
          >
            <Input  
              value={inputAddress}
              autoFocus={showPopover} 
              // defaultValue={rawAddress}
              onChange={(e) => {
                // setInputAddress(e.currentTarget.value);
                // searchAddress(e.currentTarget.value);
                handleOnChangeInputAddress(e.currentTarget.value);
              }}  
              className={`block w-full focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate ` + errorClass}
              ref={inputRef}

            />
          </FormItem>
        </div>
        {showPopover && (
              <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                <AddressRender addressToRender={addressToSearch} handleSelectLocation={callBackSelectAddress} isOpen={setShowPopover} />
              </div>
          )}
    </div>
  );
}

export default AddressSearch;