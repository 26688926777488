import { DateRange } from "data/types";
import moment from "moment";
import { FC } from "react";

export interface DisplayDateRangeProps {
  dateRange: DateRange | undefined,
  className?: string
}

const DisplayDateRange: FC<DisplayDateRangeProps> = ({dateRange, className}) => {
  if(dateRange) {
    const renderFormatdate = "Do MMMM YYYY";
    let dateDebut: string;
    let dateFin: string;
    if(dateRange.startDate && !dateRange.endDate) {
      dateDebut = moment(dateRange.startDate).format(renderFormatdate);
      return (
        <p className={className}>Vous avez choisi la date du {dateDebut} comme date de début.</p>
      )
    } 
    if (dateRange.startDate && dateRange.endDate) {
      dateDebut = moment(dateRange.startDate).format(renderFormatdate);
      dateFin = moment(dateRange.endDate).format(renderFormatdate);

      if (dateDebut === dateFin) {
        return (
          <p className={className}>Votre événement aura lieu le {dateDebut}.</p>
        )
      }
      return (
        <p className={className}>Votre événement aura lieu du {dateDebut} au {dateFin}.</p>
      )
    }
  }
  return(<></>)
}

export default DisplayDateRange;