import React, { FC, useState } from "react";
import SocialsList from "shared/SocialsList/SocialsList";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import FormikTailWildInput from "components/FormikTailwild/FormikTailWildInput";
import FormikTailWildTextarea from "components/FormikTailwild/FormikTailWildTextarea";
import axios from "axios";

export interface PageContactProps {
  className?: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Votre nom est obligatoire"),
  email: Yup.string().email("L'adresse doit-être valide").required("Une adresse email est obligatoire"),
  phone: Yup.string().required("Le numéro de téléphone est obligatoire"),
  message: Yup.string().required("Un message est obligatoire"),
});

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const onClickHandler = ()=> {
    setIsSubmitted(true);
  }
  
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contactez-nous! 
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    🗺 Adresse
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    3024 ROUTE DE LA BOISSIÈRE,<br /> 14100 Saint-Pierre-des-Ifs,<br /> Calvados, France
                </span>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    ☎ Téléphone
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    06 20 39 96 22
                </span>
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 Nos réseaux sociaux
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              {isSuccess &&
              <span>
                  <h2 className="text-2xl font-semibold">C'est envoyé 🎉</h2>
                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                  <br />
                  <p>
                    Vous allez recevoir dans quelques instants un mail de confirmation.<br/> <br />
                    Si vous ne le recevez pas rapidement, après avoir vérifié dans vos spams, vous pouvez nous contacter directement à l'adresse suivante: <a href="mailto:h2r.event@gmail.com">h2r.event@gmail.com</a>
                  </p>
              </span>
              }
              {isError &&
              <span>
                  <h2 className="text-2xl font-semibold">Oh! Une erreur est survenue!</h2>
                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                  <p>
                    Vous pouvez nous contacter directement à l'adresse suivante: <a href="mailto:h2r.event@gmail.com">h2r.event@gmail.com</a>
                  </p>
              </span>
              }
              {(!isSuccess&&!isError)&&
                <Formik
                  initialValues={{
                    name: "",
                    email:"",
                    phone: "",
                    message:""
                  }}
                  validationSchema={validationSchema}
                  onSubmit={ (values) => {
                    axios.post("Contact", values).then((response) => {
                      // SI OK
                      setIsSuccess(true);
                    }).catch(() => {
                      setIsError(true);
                      setIsSubmitted(false);
                    })
                  }}
                >
                  <Form className="grid grid-cols-1 gap-6">
                    <Field 
                      component={FormikTailWildInput} 
                      name="name" 
                      formItemProps={{label: "Votre nom & prénom"}} 
                    />
                    <Field 
                      component={FormikTailWildInput} 
                      name="email" 
                      formItemProps={{label: "Votre email"}} 
                    />
                    <Field 
                      component={FormikTailWildInput} 
                      name="phone" 
                      formItemProps={{label: "Votre numéro de téléphone"}}
                    />
                    <Field 
                      component={FormikTailWildTextarea} 
                      name="message" 
                      formItemProps={{label: "Votre message"}} 
                      textareaProps={{rows: 5}}
                    />
                    <ButtonPrimary onClick={onClickHandler} disabled={isSubmitted} type="submit">
                      {!isSubmitted &&
                        <>Envoyer</>
                      }
                      {isSubmitted &&
                        <>
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Envoi...
                        </>
                      }
                      
                    </ButtonPrimary>
                  </Form>
                </Formik>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
