import React, { FC, useContext, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";

import { FormContextRental } from "FormContextRental";
import SectionGridFilterCard from "./SectionGridFilterCard";
import axios from "axios";
import moment from "moment";
import { Category, Material } from "data/types";
import { useHistory } from "react-router-dom";


export interface MaterialRentalStep2Props {
  className?: string;
}

const MaterialRentalStep2: FC<MaterialRentalStep2Props> = ({ className = "", }) => {

  const formContextRental = useContext(FormContextRental);
  const history = useHistory();
  
  const [materialsList, setMaterialsList] = useState<Material[]>();
  const [categoriesList, setCategoriesList] = useState<Category[]>();

  const [loaded, setLoaded] = useState<boolean>(false)
  const [noDesiredMaterialSelected, setNoDesiredMaterialSelected] = useState<boolean>(false)

  const startDate = moment(formContextRental.dateRange?.startDate).format("YYYY-MM-DD");
  const endDate = moment(formContextRental.dateRange?.endDate).format("YYYY-MM-DD");

  useEffect(() => {
    if(startDate === "Invalid date" || endDate === "Invalid date") {
      history.push("/materials-rental-step1");
    }
    const materialsRequest = axios.get("Materials/WithDates?startDate="+startDate+"&endDate="+endDate);
    const categoriesRequest = axios.get("Categories/WithoutUnavailable");
    
    axios.all([materialsRequest, categoriesRequest]).then(r => {
      console.log(r);
      if (r[0].status === 200){
        setMaterialsList(r[0].data.result);
      }

      if (r[1].status === 200){
        setCategoriesList(r[1].data.result);
      }

      setLoaded(true);
    });
  }, [loaded])

  const NextStep = () => {
    window.scrollTo(0, 0)
    if(formContextRental.desiredMaterials.length !== 0){
      setNoDesiredMaterialSelected(false);
      history.push("/materials-rental-step3");
    } else {
      setNoDesiredMaterialSelected(true);
    }
  }


  return (
    <CommonLayout 
    index="2"
    backtHref="/materials-rental-step1"
    nextHref="/materials-rental-step3"
    contentBlockWidth="6"
    onClick={NextStep}
    >
      <h2 className="text-2xl font-semibold">Quels sont vos besoins ?</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <p>Voici la liste des équipements que nous pouvons vous mettre à disposition.<br/></p>
      {noDesiredMaterialSelected && 
        <p className="text-red-600 text-center"><span className="font-bold">Merci de sélectionner au moins un matériel pour votre demande de location.</span></p>
      }
      {/* FORM */}
      { loaded && (materialsList && categoriesList)   && 
        <SectionGridFilterCard data={materialsList} categories={categoriesList} cardDisplayMoreButton />
      }
      { !loaded && 
        <div className="flex justify-center items-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span>Chargement ...</span>
        </div>
      }
    </CommonLayout>
  );
};

export default MaterialRentalStep2;
