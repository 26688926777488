import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import PageContact from "containers/PageContact/PageContact";
import PageHomeH2R from "containers/PageHome/PageHomeH2R";
import Header2 from "components/Header/Header";
import MaterialRentalStep1 from "containers/EnquipementRental/MaterialRentalStep1";
import {FormContextRental } from "FormContextRental";
import MaterialRentalStep2 from "containers/EnquipementRental/MaterialRentalStep2";
import { Person, Address, Company, DesiredMaterial, DateRange } from "data/types";
import MaterialRentalStep3 from "containers/EnquipementRental/MaterialRentalStep3";
import MaterialRentalStep4 from "containers/EnquipementRental/MaterialRentalStep4";
import MaterialRentalStep5 from "containers/EnquipementRental/MaterialRentalStep5";
import ListingProductsPage from "containers/ListingProductsPage/ListingProductsPage";
import MaterialDetailPage from "containers/MaterialDetailPage/MaterialDetailPage";
import LegalNotices from "containers/LegalNotices/LegalNotices";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHomeH2R },
  { path: "/#", exact: true, component: PageHomeH2R },

  //
  { path: "/materials-rental-step1", component: MaterialRentalStep1, },
  { path: "/materials-rental-step2", component: MaterialRentalStep2,},
  { path: "/materials-rental-step3", component: MaterialRentalStep3 },
  { path: "/materials-rental-step4", component: MaterialRentalStep4 },
  { path: "/materials-rental-step5", component: MaterialRentalStep5 },
  //
  { path: "/materials-list", component: ListingProductsPage},
  { path: "/material", component: MaterialDetailPage},

  { path:"/legalNotices", component: LegalNotices},

  { path: "/contact", component: PageContact },
  { path: "/404", component: Page404 },
  //
];

const Routes = () => {

  const [dateRangeRental, setDateRangeRental] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });
  const [locationRental, setLocationRental] = useState<string>("");
  const [addressSelectedRental, setAddressSelectedRental] = useState<Address|null>(null);
  const [AddressComplementsRental, setAddressComplementsRental] = useState<string>("");
  const [desiredMaterialsRental, setDesiredMaterialsRental] = useState<DesiredMaterial[]>([]);

  const [contactCompanyRental, setContactCompanyRental]= useState<Company|null>(null);
  const [contactPersonRental, setContactPersonRental]= useState<Person>();


  return (
    <BrowserRouter basename="/" >
      <ScrollToTop />
      <Header2 />

      <Switch>
        <FormContextRental.Provider value={{
          dateRange: dateRangeRental,
          setDateRange: setDateRangeRental,
          location: locationRental,
          setLocation: setLocationRental,
          addressSelected: addressSelectedRental,
          setAddressSelected: setAddressSelectedRental,
          AddressComplements: AddressComplementsRental,
          setAddressComplements: setAddressComplementsRental,
          desiredMaterials: desiredMaterialsRental,
          setDesiredMaterials: setDesiredMaterialsRental,
          contactCompany: contactCompanyRental,
          setContactCompany: setContactCompanyRental,
          contactPerson: contactPersonRental,
          setContactPerson: setContactPersonRental,
        }}>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}
        {/* <Route path="/*" component={() => <Page404 />}/> */}
        </FormContextRental.Provider>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
