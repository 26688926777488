import { DateRange } from "data/types";
import useWindowSize from "hooks/useWindowResize";
import { FC, useState } from "react";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";

export interface SelectDateRangeProps {
  className?: string,
  dateRange: DateRange | undefined
  setDateRangeValue: (date: DateRange) => void
}

const SelectDateRange: FC<SelectDateRangeProps> = ({dateRange, setDateRangeValue, className}) => {

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =  useState<FocusedInputShape>("startDate");
  const windowSize = useWindowSize();
  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  if(!dateRange) {
    dateRange= {
      startDate: null,
      endDate: null,
    }
  }


  return (
    <>
      <div className={`listingSection__wrap__DayPickerRangeController flow-root ${className}`}>
        <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
          <DayPickerRangeController
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onDatesChange={(date) => setDateRangeValue(date)}
            focusedInput={focusedInputSectionCheckDate}
            onFocusChange={(focusedInput) =>
              setFocusedInputSectionCheckDate(focusedInput || "startDate")
            }
            minimumNights={0}
            initialVisibleMonth={null}
            numberOfMonths={windowSize.width < 1280 ? 1 : 2}
            daySize={getDaySize()}
            firstDayOfWeek={1}
            hideKeyboardShortcutsPanel
          />
        </div>
      </div>
    </>
  );
}

export default SelectDateRange